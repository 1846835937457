<template>
	<div @click="dos" class="advert-box">
		<div class="wbanner">
			<van-swipe class="my-swipe" :autoplay="advertTime" indicator-color="white">
				<van-swipe-item v-for="(item, index) in advertWai" :key="item.id">
					<img class="wadv" :src="item" />
				</van-swipe-item>
			</van-swipe>
		</div>
	</div>
</template>
<script>
	import { arrayEqual } from '../utils/tool.js'
	export default {
		data() {
			return {
				advertTime: 10000,
				advertWai: [],
				advertTimer: ''
			}
		},
		mounted() {
			this.getAdvertWai()
		},
		beforeDestroy() {
			clearTimeout(this.advertTimer)
		},
		methods: {
			// 获取外屏广告
			getAdvertWai() {
				let arr = JSON.parse(localStorage.getItem('advertWai'))
				if (!arrayEqual(arr, this.advertWai)) {
					this.advertWai = arr
				}
				
				clearTimeout(this.advertTimer)
				this.advertTimer = setTimeout(() => {
					this.getAdvertWai()
				}, 1000 * 60 * 10)
			},
			dos() {
				this.$router.replace('/')
			}
		}
	}
</script>
<style scoped>
	.advert-box {
		width: 1080px;
		height: 1920px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.wbanner {
		width: 1080px;
		height: 1920px;
		overflow: hidden;
	}

	.wadv {
		width: 1080px;
		height: 1920px;
	}
</style>
