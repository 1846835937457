export function arrContains(arr1, arr2) {
	return arr2.every(val => arr1.includes(val));
}

/**
 * JS判断两个数组是否相等
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {boolean} 返回true 或 false
 */
export function arrayEqual(arr1, arr2) {
	if (arr1 === arr2) return true;
	if (arr1.length != arr2.length) return false;
	for (var i = 0; i < arr1.length; ++i) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
}
